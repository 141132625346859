import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import * as style from './Segments.module.less';
import { Container, Row, Col, Table } from 'react-bootstrap';
import {
    formatDollarStr,
    formatPercentStr,
    getFloat,
    getInt,
    MaxSize
} from '@assets/utils/Formatting';
import { parseInt } from 'lodash';

export const Segments = observer(() => {
    const store = useStore();
    var segments = store.segments_by_service;
    // if (!segments || segments.length === 0) {
    //     segments = store.segments_by_location;
    // }

    const sorted = segments
        .slice()
        .sort((a, b) => getFloat(b.share) - getFloat(a.share));

    const totalRev = sorted.reduce(
        (acc, seg) => acc + parseInt(seg.revenue),
        0
    );
    sorted.push({
        service: 'Total',
        segment: 'Total',
        short_description: '',
        revenue: totalRev.toString(),
        share: '100'
    });

    var maxSize: MaxSize = 'M';
    sorted.forEach(seg => {
        var rev = parseInt(seg.revenue);
        if (rev > 1000000000) {
            maxSize = 'B';
        }
    });
    //maxSize = 'M';

    const elems = sorted.map((seg, index) => {
        const rev = formatDollarStr(seg.revenue.toString(), maxSize);
        const tdName = [style.tbody_td, style.padding_left].join(' ');
        const tdValue = [style.tbody_td, style.value].join(' ');
        const trStyle = [style.tbody_tr].join(' ');

        return (
            <tr className={trStyle} key={index}>
                <td className={tdName}>
                    <b>{seg.service ?? seg.segment}</b> {seg.short_description}
                </td>
                <td className={tdValue}>{rev}</td>
                <td className={tdValue}>{formatPercentStr(seg.share)}</td>
            </tr>
        );
    });

    const yearly = store.yearly_summary;
    var maxYear = yearly.length > 0 ? parseInt(yearly[2].year) : '';

    return (
        <Table className={style.segmentsTable}>
            <thead>
                <tr>
                    <th className={style.thead_th}>Segment</th>
                    <th className={style.thead_th}>Revenue ({maxYear})</th>
                    <th className={style.thead_th}>Share</th>
                </tr>
            </thead>
            <tbody>{elems}</tbody>
        </Table>
    );
});
