import { Col, Container, Row } from 'react-bootstrap';
import * as style from './ContentHeader.module.less';
import { observer } from 'mobx-react';
import { useStore } from '@assets/model/Store';
import { StockInfo } from './StockInfo/StockInfo';

export const ContentHeader = observer(() => {
    const store = useStore();
    const error = store.errorMessageTicker;

    if (error) {
        return (
            <Container className={style.header}>
                <Row className="d-flex align-items-center h-100">
                    <Col md={12} className={style.title}>
                        <i className="bi bi-exclamation-triangle text-danger"></i>{' '}
                        {store.ticker} - {error}
                    </Col>
                </Row>
            </Container>
        );
    }
    return (
        <Container className={style.header}>
            <Row className="d-flex align-items-center h-100">
                <Col md={7} className={style.title}>
                    {store.company_name} •︎ {store.ticker}
                </Col>
                <Col md={5}>
                    <StockInfo />
                </Col>
            </Row>
        </Container>
    );
});
