import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { Col } from 'react-bootstrap';
import * as style from './KeyPeople.module.less';

type KeyPeopleType = 'directors' | 'executives';

interface KeyPeopleProps {
    type: KeyPeopleType;
}

export const KeyPeople = observer((props: KeyPeopleProps) => {
    const store = useStore();

    if (!store.executives || !store.directors) {
        return null;
    }

    const list =
        props.type === 'directors' ? store.directors : store.executives;

    const title =
        props.type === 'directors' ? 'Board of Directors' : 'Key executives';
    const LIMIT = 10;
    const display = [];
    for (let i = 0; i < list.length && i < LIMIT; i++) {
        const person = list[i];
        const yearStarted =
            !person.started_job_year || person.started_job_year == 'N/A'
                ? ''
                : ' (' + person.started_job_year + ')';
        display.push(
            <div key={i}>
                <div className={style.bullet}>▪</div>
                <div className={style.entry}>
                    <b>{person.name}</b> - {person.position}
                    {yearStarted}
                </div>
            </div>
        );
    }

    return (
        <Col md={6} className={style.container}>
            <div className={style.title}>{title}</div>
            {display}
        </Col>
    );
});
