import { Col, Container, Row } from 'react-bootstrap';
import { TickerSearch } from '../TickerSearch/TickerSearch';
import { Logo } from './Logo';
import * as style from './Header.module.less';

export const Header = () => {
    return (
        <Container className={style.header + ' d-flex align-items-center'}>
            <Row className="w-100">
                <Col md={2} className={style.logo}>
                    <Logo />
                </Col>
                <Col></Col>
                <Col md={3}>
                    <TickerSearch />
                </Col>
            </Row>
        </Container>
    );
};
