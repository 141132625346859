import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import * as style from './KPIsList.module.less';
import { formatDollarStr } from '@assets/utils/Formatting';

export const KPIsView = observer(() => {
    const store = useStore();
    const kpis = store.kpis;
    const elems = kpis.map((kpi, index) => {
        var value = kpi.value;
        return (
            <div className={style.entry} key={index}>
                <div>{value}</div>
                <div className={style.desc}>{kpi.kpi}</div>
            </div>
        );
    });
    return (
        <div className={'d-flex ' + style.container}>
            <div className={style.entry + ' ' + style.titleKpi}>KPIs</div>
            {elems}
        </div>
    );
});
