import { observer } from 'mobx-react';
import * as style from './Panel.module.less';
import { Container } from 'react-bootstrap';

interface PanelProps {
    title: string;
    children?: React.ReactNode;
    className?: string;
}

export const Panel = observer((props: PanelProps) => {
    const className = props.className || '';
    return (
        <div className={style.panel}>
            <div className={style.title}>{props.title}</div>
            <Container className={className + ' ' + style.content}>
                {props.children}
            </Container>
        </div>
    );
});
