import { Backend } from '@assets/backend/backend';
import { getInt, isInt } from '@assets/utils/Formatting';
import { computed, observable, values } from 'mobx';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import { KPIsView } from '@assets/component/Content/KPIsList/KPIsList';

function isString(s) {
    return typeof s === 'string' || s instanceof String;
}

interface StockInfo {
    ticker: string;
    current: number;
    eps: number;
    avg_over_year: number;
}

interface TranscriptEntry {
    highlight: string;
    source_text: string;
}
interface KPI {
    kpi: string;
    value: string;
}

interface YearlyFinance {
    cogs: string;
    ebitda: string;
    revenue: string;
    year: string;
    ebitda_margin: string;
    yoy_growth: string;
}
interface KeyPerson {
    name: string;
    position: string;
    started_job_year: string;
    bio: string;
}

interface Segment {
    revenue: string;
    service: string;
    segment: string;
    share: string;
    short_description: string;
}

interface ActiveJob {
    ticker: string;
    status: string;
    task: string;
}

interface ModalDisplay {
    title: string;
    transcript: TranscriptEntry;
}

class Store {
    constructor() {}

    @observable
    accessor modal: ModalDisplay = null;

    @observable
    accessor updatedStockInfo: StockInfo = null;

    @observable
    accessor waitingForTickerToFinish: string = '';

    @observable
    accessor uiSearchTicker: string = '';

    @observable
    accessor reportDataDB: any = null;

    @observable
    accessor jobs: any = {};

    @observable
    accessor systemStatus: any = {};

    @observable accessor activeJob: ActiveJob = null;

    @computed get queueJobs() {
        if (!this.jobs) {
            return [];
        }
        var activeJob = this.activeJob;

        var jobs = values(this.jobs);
        jobs = jobs.filter((job: any) => {
            return job.status == 'pending';
        });

        jobs = jobs.toSorted((a: any, b: any) => {
            return a.createdAt - b.createdAt;
        });

        return jobs;
    }

    @computed get isSystemOnline() {
        if (!this.systemStatus || this.systemStatus?.status == undefined) {
            return true;
        }

        return this.systemStatus?.status === 'online';
    }

    @computed get transcripts(): TranscriptEntry[] {
        if (!this.activeReport?.transcripts) {
            return [];
        }
        return this.activeReport?.transcripts as TranscriptEntry[];
    }
    @computed get processingJobName() {
        if (this.activeJob?.status != 'processing') {
            return '';
        }
        return this.activeJob?.ticker ?? '';
    }

    @computed get selectedTickerId() {
        return this.uiSearchTicker.toLocaleUpperCase();
    }

    async fetchNewStockPrice(ticker: string) {
        try {
            const url = `https://financialmodelingprep.com/api/v3/quote/${ticker}?apikey=TS5EMk7UvNGbPSD6oO20yeQL2CuhZV0l`;
            const resp = await axios.get(url);
            const data = resp.data[0];
            const stockInfo: StockInfo = {
                ticker: data.symbol,
                current: data.price,
                eps: data.eps,
                avg_over_year: data.priceAvg200
            };

            const epsUrl = `https://financialmodelingprep.com/api/v3/income-statement/${ticker}?period=quarter&apikey=TS5EMk7UvNGbPSD6oO20yeQL2CuhZV0l`;
            const resp2 = await axios.get(epsUrl);
            if (resp2.data.length >= 4) {
                var totalIncome = 0;
                for (var i = 3; i >= 0; i--) {
                    const d = resp2.data[i];
                    totalIncome += d.netIncome;
                }

                const avgEps =
                    totalIncome / resp2.data[0].weightedAverageShsOut;

                stockInfo.eps = avgEps;
            }

            this.updatedStockInfo = stockInfo;
        } catch (e) {}
    }

    @computed get activeReport() {
        const ticker = this.selectedTickerId;
        if (!this.reportDataDB) {
            return null;
        }

        if (this.reportDataDB.hasOwnProperty(ticker)) {
            this.updatedStockInfo = null;
            this.fetchNewStockPrice(ticker);
            return this.reportDataDB[ticker];
        }

        return null;
    }

    @computed
    get company_name() {
        return this.activeReport?.company_name;
    }

    @computed
    get ticker() {
        return this.activeReport?.ticker;
    }

    @computed get reportCreationDate() {
        if (!this.activeReport?.created) return null;

        return moment.unix(this.activeReport?.created).format('YYYY-MM-D');
    }

    @computed get errorMessageTicker() {
        if (!this.activeReport?.error) return null;
        return this.activeReport?.error;
    }
    @computed get reportFiscalYear() {
        if (!this.activeReport?.report_fiscal_year) return null;

        return this.activeReport?.report_fiscal_year;
    }
    @computed
    get stockInfo(): StockInfo {
        if (
            this.updatedStockInfo &&
            this.updatedStockInfo.ticker == this.selectedTickerId
        ) {
            return this.updatedStockInfo;
        }

        return this.activeReport?.stock_info as StockInfo;
    }

    @computed get companyOverview() {
        return this.activeReport?.company_overview;
    }

    @computed get directors(): KeyPerson[] {
        if (
            !this.activeReport?.directors ||
            isString(this.activeReport?.directors)
        ) {
            return [];
        }
        return this.activeReport?.directors as KeyPerson[];
    }

    @computed get executives(): KeyPerson[] {
        if (
            !this.activeReport?.executives ||
            isString(this.activeReport?.executives)
        ) {
            return [];
        }
        return this.activeReport?.executives as KeyPerson[];
    }

    @computed get yearly_summary(): YearlyFinance[] {
        const yearly = this.activeReport?.yearly_summary;
        if (!yearly) {
            return [];
        }

        var sorted = yearly.slice().sort((a: any, b: any) => {
            return parseInt(a.year) - parseInt(b.year);
        });

        sorted[0].yoy_growth = '';

        for (var i = 0; i < sorted.length; i++) {
            if (!isInt(sorted[i].revenue)) {
                sorted[i].revenue = 'N/A';
            }
            if (!isInt(sorted[i].ebitda)) {
                sorted[i].ebitda = 'N/A';
            }

            var ebitda = getInt(sorted[i].ebitda);
            var revenue = getInt(sorted[i].revenue);

            if (ebitda == 0 || revenue == 0) {
                sorted[i].ebitda_margin = 'N/A';
            } else {
                const ebitda_margin = (ebitda / revenue) * 100;
                sorted[i].ebitda_margin = ebitda_margin.toFixed(2);
            }
        }

        for (var i = 1; i < sorted.length; i++) {
            var curr = getInt(sorted[i].revenue);
            var last = getInt(sorted[i - 1].revenue);

            if (curr == 0 || last == 0) {
                sorted[i].yoy_growth = 'N/A';
            } else {
                const yoy = curr / last - 1;
                sorted[i].yoy_growth = (yoy * 100).toFixed(2);
            }
        }

        const ttm = this.activeReport?.ttm_summary;
        if (!isInt(ttm.revenue)) {
            ttm.revenue = 'N/A';
        }
        if (!isInt(ttm.ebitda)) {
            ttm.ebitda = 'N/A';
        }

        const ebitdaValue = getInt(ttm.ebitda);
        const revenueValue = getInt(ttm.revenue);
        if (ebitdaValue == 0 || revenueValue == 0) {
            ttm.ebitda_margin = 'N/A';
        } else {
            ttm.ebitda_margin = (
                (getInt(ttm.ebitda) / getInt(ttm.revenue)) *
                100
            ).toFixed(2);
        }
        ttm.yoy_growth = '';
        sorted = sorted.slice(1);
        return [...sorted, ttm] as YearlyFinance[];
    }

    @computed get yearly_summary_last_year(): YearlyFinance {
        if (this.yearly_summary.length < 2) {
            return null;
        }
        return this.yearly_summary[this.yearly_summary.length - 2];
    }

    @computed get kpis(): KPI[] {
        if (!this.activeReport?.kpi_values?.kpis) {
            return [];
        }

        var copy = this.activeReport?.kpi_values?.kpis?.slice();
        copy = copy.filter((kpi: any) => {
            return (
                typeof kpi.value !== 'object' &&
                kpi.value !== 'N/A' &&
                kpi.value.length < 100
            );
        });

        if (copy.length > 4) {
            copy.length = 4;
        }
        return copy as KPI[];
    }

    @computed get segments_by_service(): Segment[] {
        var segments = [];

        try {
            segments = this.activeReport.business_highlights_by_service.slice();
        } catch {
            //If it fails just assume no segment data
            segments = [];
        }

        segments = segments.filter((seg: Segment) => {
            return isInt(seg.revenue);
        });

        segments = segments.sort((a: Segment, b: Segment) => {
            return getInt(b.revenue) - getInt(a.revenue);
        });

        if (segments.length > 7) {
            segments.length = 7;
        }

        const totalInSegments = segments.reduce((acc: number, seg: Segment) => {
            return acc + getInt(seg.revenue);
        }, 0);

        const totalRevFromOverview = parseInt(
            store.yearly_summary_last_year?.revenue ?? '0'
        );
        const unaccounted = totalRevFromOverview - totalInSegments;

        if (unaccounted > 0) {
            segments.push({
                service: 'Other Uncategorized',
                segment: 'Other Uncategorized',
                short_description: '',
                revenue: unaccounted.toString(),
                share: '100'
            });
        }

        segments.forEach((seg: Segment) => {
            seg.share = (
                (getInt(seg.revenue) / totalRevFromOverview) *
                100
            ).toFixed(1);
        });

        return segments as Segment[];
    }

    // @computed get segments_by_location(): Segment[] {
    //     if (!this.activeReport?.business_highlights_by_location) {
    //         return [];
    //     }

    //     if (this.activeReport?.business_highlights_by_location == 'N/A') {
    //         return [];
    //     }

    //     var segments =
    //         this.activeReport?.business_highlights_by_location.slice();

    //     segments = segments.filter((seg: Segment) => {
    //         return isInt(seg.revenue);
    //     });

    //     const total = segments.reduce((acc: number, seg: Segment) => {
    //         return acc + getInt(seg.revenue);
    //     }, 0);

    //     segments.forEach((seg: Segment) => {
    //         seg.share = ((getInt(seg.revenue) / total) * 100).toFixed(1);
    //     });

    //     if (segments.length > 6) {
    //         segments.length = 6;
    //     }
    //     return segments as Segment[];
    // }
}

const store = new Store();

export const useStore = () => store;
export const useBackend = () => {
    return new Backend();
};
export default store;
