import { Col, Container, Row } from 'react-bootstrap';
import * as style from './Content.module.less';
import { ContentHeader } from './ContentHeader/ContentHeader';
import { Panel } from '../Panel/Panel';
import { CompanyOverview } from './CompanyOverview/CompanyOverview';
import { KeyPeople } from './KeyPeople/KeyPeople';
import { FinancialsYearly } from './FinancialsYearly/FinancialsYearly';
import { KPIsView } from './KPIsList/KPIsList';
import { Segments } from '../Segments/Segments';

export const Content = () => {
    return (
        <Container className={style.root}>
            <Row>
                <Col>
                    <ContentHeader />
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <Panel title="Company Overview">
                        <CompanyOverview />
                    </Panel>
                </Col>
                <Col md={6}>
                    <Panel title="Financial Highlights">
                        <FinancialsYearly />
                        <KPIsView />
                    </Panel>
                </Col>
            </Row>

            <Row className="pt-3">
                <Col md={6}>
                    <Panel title="Segment Overview">
                        <Segments />
                    </Panel>
                </Col>
                <Col md={6}>
                    <Panel title="Key Executives & Board of Directors">
                        <Row>
                            <KeyPeople type="executives" />
                            <KeyPeople type="directors" />
                        </Row>
                    </Panel>
                </Col>
            </Row>
        </Container>
    );
};
