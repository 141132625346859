import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';

import * as style from './CompanyOverview.module.less';

export const CompanyOverview = observer(() => {
    const store = useStore();
    return (
        <>
            <Row className={style.row}>
                <Col>
                    <div className={style.title}>Business Description</div>
                    <p>{store.companyOverview}</p>
                </Col>
            </Row>
            <Row className={style.rowTranscripts}>
                <Col>
                    <div className={style.title}>Earnings Call Highlights</div>
                    <ul>
                        {store.transcripts.map((transcript, index) => {
                            return (
                                <li key={index}>
                                    {transcript.highlight}{' '}
                                    <span
                                        className={style.textLink}
                                        onClick={() => {
                                            store.modal = {
                                                title: 'Transcript Highlight Source',
                                                transcript: transcript
                                            };
                                        }}
                                    >
                                        [+]
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </Col>
            </Row>
        </>
    );
});
