import { NavigateFunction, useNavigate } from 'react-router-dom';

export class NavUtils {
	constructor(private nav: NavigateFunction) {
	}

	toTicker(tickerId: string) {
		this.nav(`/${tickerId}`);
	}

	back() {
		this.nav(-1);
	}
}


export function useNavUtils() {
	const nav = useNavigate();
	return new NavUtils(nav);
}