import { observer } from 'mobx-react';
import { withRouter } from 'react-router-class-tools';

import { Content } from '@assets/component/Content/Content';
import { Header } from '@assets/component/Header/Header';
import { IsOnline } from '@assets/component/IsOnline/IsOnline';
import { ProcessingReport } from '@assets/component/ProcessingReport/ProcessingReport';
import { ReportTime } from '@assets/component/ReportTime/ReportTime';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { i18n } from '../model/Translation';
import { useEffect } from 'react';
import store, { useStore } from '@assets/model/Store';
import { QueueJobs } from '@assets/component/QueueJobs/QueueJobs';
import ModalContainer from '@assets/component/ModalContainer/ModalContainer';

const { t } = i18n;

const HomePage = observer(() => {
    const { id } = useParams();
    const store = useStore();
    useEffect(() => {
        if (id) {
            store.uiSearchTicker = id;
        }
    }, [id]);
    return (
        <>
            <ModalContainer />
            <Container className="w-100 h-100">
                <Row className="d-flex justify-content-between">
                    <Col>
                        <Header />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Content />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ReportTime />
                    </Col>
                </Row>
            </Container>
            <ProcessingReport />
            <QueueJobs />
        </>
    );
});

export default withRouter(HomePage);
