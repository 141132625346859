import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Home';
import LoginPage from './Login';
import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';

export const PageFrame = observer(() => {
    const store = useStore();
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/:id" element={<HomePage />} />
                </Routes>
            </Router>
        </>
    );
});
