export const getInt = (s: string) => {
	try {
		if (!s) return 0;
		if (s == 'N/A') return 0;

		return parseInt(s.toString().match(/(-)?\d+[\.,\d+]*/)[0].replace(',', ''));
	} catch (e) {
		return 0;
	}
};

export const getFloat = (s: string) => {
	try {
		if (!s) return 0;
		if (s == 'N/A') return 0;

		return parseFloat(s.match(/(-)?\d+[\.,\d+]*/)[0].replace(',', ''));
	} catch (e) {
		return 0;
	}
}


export const isFloat = (s: string) => {
	try {
		if (!s) return false;
		if (s == 'N/A') return false;

		return !isNaN(parseFloat(s.match(/\d+[\.,\d+]*/)[0]));

	} catch (e) {
		return false;
	}
}
export const isInt = (s: string) => {
	try {
		if (!s) return false;
		if (s == 'N/A') return false;

		return !isNaN(parseInt(s.toString().match(/\d+[\.,\d+]*/)[0]));

	} catch (e) {
		return false;
	}
}

function isString(s: any) {
	return typeof s === 'string' || s instanceof String;
}

export type MaxSize = 'M' | 'B' | 'auto';

export const formatDollarStr = (s: string, sizeConversion: MaxSize = 'auto') => {
	function formatNumberCommas(num) {
		return num.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}

	if (!s) {
		return '';
	}

	if (isString(s)) {
		var str = s as string;
		if (str == '' || str == 'N/A') return '';
		if (isFloat(str)) {
			var num = getFloat(str);
			var absNum = Math.abs(num);
			var letterToAdd = ''

			if (sizeConversion === 'auto') {
				if (absNum > 1000000000) {
					letterToAdd = 'B';
					num = num / 1000000000;
				} else if (absNum > 1000000) {
					letterToAdd = 'M';
					num = num / 1000000;
				} else {
					if (str.includes('M') || str.includes('m')) {
						letterToAdd = 'M';
					}
					else if (str.includes('B') || str.includes('b')) {
						letterToAdd = 'B';
					}
				}
			} else {
				if (sizeConversion === 'B') {
					letterToAdd = 'B';
					num = num / 1000000000;
				} else if (sizeConversion === 'M') {
					letterToAdd = 'M';
					num = num / 1000000;
				}
			}



			var numDisplay = formatNumberCommas(Math.abs(num)) + letterToAdd;
			if (numDisplay.includes('.00')) {
				numDisplay = numDisplay.replace('.00', '');
			}
			if (num > 0) {
				return '$' + numDisplay;
			} else {
				return '-$' + numDisplay;
			}
		}
	} else {
		return '';
	}
}

export const formatPercentStr = (s: string) => {
	if (!s) {
		return '';
	}

	if (isString(s)) {
		var str = s as string;
		if (str == '' || str == 'N/A') return '';
		if (isFloat(str)) {
			return str + '%';
		}
	} else {
		return '';
	}
};
