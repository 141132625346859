import { observer } from 'mobx-react';
import * as style from './ProcessingReport.module.less';
import workSVG from './Work.svg';
import { useStore } from '@assets/model/Store';
export const ProcessingReport = observer(() => {
    const store = useStore();
    const job = store.activeJob ?? null;

    if (!job) {
        return null;
    }

    const isActive = job.status === 'pending' || job.status === 'processing';

    //make better
    const txt_active = isActive ? style.text_active : '';
    const svg_active = isActive ? style.svg_active : '';
    const task_active = isActive ? style.task_active : '';
    return (
        <>
            <img src={workSVG} className={style.svg + ' ' + svg_active}></img>
            <div className={style.text + ' ' + txt_active}>
                {job.ticker.toUpperCase()}
            </div>
            <div className={style.task + ' ' + task_active}>{job.task}</div>
        </>
    );
});
