import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { Table } from 'react-bootstrap';
import * as style from './FinancialsYearly.module.less';
import { formatDollarStr, formatPercentStr } from '@assets/utils/Formatting';

export const FinancialsYearly = observer(() => {
    const store = useStore();

    const years = store.yearly_summary;
    const headerTH = [style.thead_th].join(' ');
    const rowTD_Title = [style.tbody_td, style.row_title].join(' ');
    const rowTD_Value = [style.tbody_td, style.value].join(' ');

    return (
        <Table className={style.financeTable}>
            <thead>
                <tr>
                    <th className={headerTH}>Financials</th>
                    {years.map((yearly, index: number) => {
                        return (
                            <th className={headerTH} key={index}>
                                {yearly.year}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={rowTD_Title}>Revenue</td>
                    {years.map((yearly, index: number) => {
                        return (
                            <td key={index} className={rowTD_Value}>
                                {formatDollarStr(yearly.revenue.toString())}
                            </td>
                        );
                    })}
                </tr>
                <tr className={style.tbody_tr}>
                    <td className={rowTD_Title}>
                        <i>YoY Growth</i>
                    </td>
                    {years.map((yearly, index: number) => {
                        return (
                            <td key={index} className={rowTD_Value}>
                                <i>{formatPercentStr(yearly.yoy_growth)}</i>
                            </td>
                        );
                    })}
                </tr>
                <tr>
                    <td className={rowTD_Title}>EBITDA</td>
                    {years.map((yearly, index: number) => {
                        return (
                            <td key={index} className={rowTD_Value}>
                                {formatDollarStr(yearly.ebitda.toString())}
                            </td>
                        );
                    })}
                </tr>
                <tr>
                    <td className={rowTD_Title}>
                        <i>EBITDA Margin</i>
                    </td>
                    {years.map((yearly, index: number) => {
                        return (
                            <td key={index} className={rowTD_Value}>
                                <i>{formatPercentStr(yearly.ebitda_margin)}</i>
                            </td>
                        );
                    })}
                </tr>
            </tbody>
        </Table>
    );
});
