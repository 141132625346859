import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import * as style from './StockInfo.module.less';
import { formatDollarStr } from '@assets/utils/Formatting';
export const StockInfo = observer(() => {
    const store = useStore();

    const fields = [
        { field: 'current', label: 'Current Price' },
        { field: 'avg_over_year', label: '200 Day MA' },
        { field: 'eps', label: 'TTM Basic EPS' }
    ];

    if (!store.stockInfo) {
        return null;
    }
    return (
        <div className={'d-flex w-100 justify-content-end ' + style.container}>
            {fields.map((f, index) => {
                var value = 'N/A';
                try {
                    value = store.stockInfo[f.field].toFixed(2);
                } catch (e) {
                    console.error(e);
                }

                return (
                    <div key={index} className="p-3">
                        <div className={style.label}>{f.label}</div>
                        <div className={style.value}>
                            {formatDollarStr(value)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
});
