import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import style from './ReportTime.module.less';
import { Container } from 'react-bootstrap';

export const ReportTime = observer(() => {
    const store = useStore();

    if (!store.reportCreationDate) {
        return null;
    }

    return (
        <div className={style.reportDate}>
            <div>
                <i className="bi bi-floppy"></i> Created:{' '}
                {store.reportCreationDate}
            </div>
            {store.reportFiscalYear && (
                <div className={style.source}>
                    Source: {store.reportFiscalYear}/10K
                </div>
            )}
        </div>
    );
});
