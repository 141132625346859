import { observer } from 'mobx-react';
import * as style from './QueueJobs.module.less';
import { useStore } from '@assets/model/Store';

export const QueueJobs = observer(() => {
    const store = useStore();
    const jobsQueue = store.queueJobs.map((job, index) => {
        return (
            <tr key={job.tickerId}>
                <td>{job.tickerId.toUpperCase()}</td>
            </tr>
        );
    });

    if (jobsQueue.length === 0) {
        return null;
    }

    return (
        <div className={style.queueContainer}>
            <table>
                <thead>
                    <tr>
                        <th>Queue</th>
                    </tr>
                </thead>
                <tbody>{jobsQueue}</tbody>
            </table>
        </div>
    );
});
